import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  icon: string
}

export const TabIcon = memo(function TabIcon({ icon }: Props) {
  const SVG = () => {
    switch (icon) {
      case 'train':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 23.34 24.5"
          >
            <path
              data-name="Unione 9"
              d="m21.951 23.918-.432-.869H1.859l-.436.869a.6.6 0 0 1-.539.332.566.566 0 0 1-.264-.065.6.6 0 0 1-.273-.8l2.4-4.8a.607.607 0 0 1 .809-.271.606.606 0 0 1 .266.8l-.164.332h16.055l-.158-.332a.6.6 0 0 1 .264-.8.6.6 0 0 1 .8.271l2.4 4.8a.6.6 0 0 1-.271.8.562.562 0 0 1-.266.065.6.6 0 0 1-.531-.332Zm-19.494-2.07h18.465l-.6-1.2H3.064Zm3.232-3.6a3 3 0 0 1-3-3v-12a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3Zm-1.8-15v12a1.8 1.8 0 0 0 1.8 1.8h12a1.8 1.8 0 0 0 1.8-1.8v-12a1.8 1.8 0 0 0-1.8-1.8h-12a1.8 1.8 0 0 0-1.8 1.801Zm10.8 10.8a1.8 1.8 0 0 1 1.8-1.8 1.8 1.8 0 0 1 1.8 1.8 1.8 1.8 0 0 1-1.8 1.8 1.8 1.8 0 0 1-1.8-1.799Zm1.2 0a.6.6 0 0 0 .6.6.6.6 0 0 0 .6-.6.6.6 0 0 0-.6-.6.6.6 0 0 0-.6.601Zm-10.8 0a1.8 1.8 0 0 1 1.8-1.8 1.8 1.8 0 0 1 1.8 1.8 1.8 1.8 0 0 1-1.8 1.8 1.8 1.8 0 0 1-1.8-1.799Zm1.2 0a.594.594 0 0 0 .6.6.6.6 0 0 0 .6-.6.6.6 0 0 0-.6-.6.6.6 0 0 0-.6.601Zm-.6-3a.6.6 0 0 1-.6-.6v-4.8a.6.6 0 0 1 .6-.6h12a.6.6 0 0 1 .6.6v4.8a.6.6 0 0 1-.6.6Zm6.6-1.2h4.8v-3.6h-4.8Zm-6 0h4.8v-3.6h-4.8Zm1.8-6a.6.6 0 0 1-.6-.6.6.6 0 0 1 .6-.6h7.2a.6.6 0 0 1 .6.6.6.6 0 0 1-.6.6Z"
              strokeMiterlimit={10}
              strokeWidth={0.5}
            />
          </svg>
        )
      case 'plane':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 27.58 25"
          >
            <path
              data-name="Tracciato 532"
              d="M10.556 24.5H7.762a.416.416 0 0 1-.415-.416.4.4 0 0 1 .034-.163l3.844-8.967H6.593l-3.534 2.981a.416.416 0 0 1-.267.1H.942a.415.415 0 0 1-.415-.413.408.408 0 0 1 .026-.142l1.8-4.975L.548 7.523a.415.415 0 0 1 .248-.531.432.432 0 0 1 .142-.024h1.854a.416.416 0 0 1 .267.1l3.534 2.984h4.632L7.381 1.079a.415.415 0 0 1 .218-.545A.421.421 0 0 1 7.762.5h2.795a.411.411 0 0 1 .326.159l7.345 9.39h4.736c3.613 0 4.09 2.278 4.109 2.374a.425.425 0 0 1 0 .157c-.018.1-.5 2.374-4.109 2.374h-4.737l-7.345 9.387a.411.411 0 0 1-.326.159Zm-2.165-.829h1.962l7.345-9.386a.418.418 0 0 1 .327-.159h4.938c2.568 0 3.161-1.29 3.271-1.623-.111-.338-.7-1.624-3.271-1.624h-4.937a.415.415 0 0 1-.327-.159l-7.345-9.39H8.391l3.844 8.971a.415.415 0 0 1-.218.545.422.422 0 0 1-.164.034H6.441a.423.423 0 0 1-.267-.1L2.64 7.795H1.534l1.648 4.565a.416.416 0 0 1 0 .282l-1.648 4.563H2.64l3.534-2.981a.416.416 0 0 1 .267-.1h5.412a.414.414 0 0 1 .415.415.425.425 0 0 1-.033.164Z"
            />
          </svg>
        )

      default:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24.5 20.653"
          >
            <path
              data-name="Unione 10"
              d="M18.965 20.403a1.44 1.44 0 0 1-1.436-1.442v-2.4H6.972v2.39a1.436 1.436 0 0 1-1.43 1.45H2.667a1.45 1.45 0 0 1-1.451-1.45v-2.649a1.926 1.926 0 0 1-.959-1.661V9.71a.354.354 0 0 1 0-.143 2.94 2.94 0 0 1 2.572-2.553l1.916-5.457A1.926 1.926 0 0 1 6.589.251h11.8a1.926 1.926 0 0 1 1.9 1.326l1.92 5.564a2.876 2.876 0 0 1 2.031 2.426.441.441 0 0 1 0 .143v4.931a1.919 1.919 0 0 1-.961 1.661v2.657a1.44 1.44 0 0 1-1.437 1.442Zm-16.787-1.45a.5.5 0 0 0 .49.49h2.875a.482.482 0 0 0 .471-.49v-2.39H2.178Zm16.311 0a.479.479 0 0 0 .477.478h2.881a.479.479 0 0 0 .479-.478v-2.39H18.49Zm-17.271-9v4.688a.96.96 0 0 0 .961.96h20.146a.957.957 0 0 0 .959-.96V9.953a1.232 1.232 0 0 0-.008-.124 2.08 2.08 0 0 0-2.246-1.9H3.297a2.085 2.085 0 0 0-2.08 2.024Zm4.475-8.062L3.839 6.966H21.11l-1.756-5.094a.966.966 0 0 0-.961-.661h-11.8a.954.954 0 0 0-.901.682Zm12.8 9.875a1.918 1.918 0 0 1 1.92-1.92 1.918 1.918 0 0 1 1.916 1.92 1.918 1.918 0 0 1-1.916 1.92 1.918 1.918 0 0 1-1.925-1.918Zm.961 0a.958.958 0 0 0 .959.96.957.957 0 0 0 .957-.96.957.957 0 0 0-.957-.96.957.957 0 0 0-.964.962Zm-12 1.92a.481.481 0 0 1-.48-.482v-2.88a.48.48 0 0 1 .48-.478h9.6a.481.481 0 0 1 .479.478v2.88a.481.481 0 0 1-.479.482Zm.48-.96h8.635v-1.92H7.934Zm-5.756-.96a1.918 1.918 0 0 1 1.92-1.92 1.918 1.918 0 0 1 1.916 1.92 1.918 1.918 0 0 1-1.916 1.92 1.918 1.918 0 0 1-1.92-1.918Zm.959 0a.961.961 0 0 0 .961.96.96.96 0 0 0 .959-.96.96.96 0 0 0-.959-.96.96.96 0 0 0-.961.962Z"
              strokeMiterlimit={10}
              strokeWidth={0.5}
            />
          </svg>
        )
    }
  }

  return <Icon>{SVG()}</Icon>
})

const Icon = styled.div`
  width: auto;
  height: 1.5rem;
  margin-right: 1rem;
  fill: ${({ theme }) => theme.colors.variants.neutralLight3};
  stroke: ${({ theme }) => theme.colors.variants.neutralLight3};

  @media (max-width: 767px) {
    margin-right: 0.5rem;
  }

  @media (max-width: 474px) {
    display: none;
  }
`
