import 'react-tabs/style/react-tabs.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Line } from 'app/components/Common/Line'
import React, { memo } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

import { TabIcon } from './TabIcon'

export interface Props {
  directions?: string[]
  label?: string
  title?: string
}

export const Directions = memo(function Directions({
  directions,
  label,
  title,
}: Props) {
  if (!directions) {
    return null
  }

  return (
    <Container>
      <FadeInUp>
        {label ? <Label>{label}</Label> : null}
        {title ? <Title dangerouslySetInnerHTML={{ __html: title }} /> : null}
        <Line className="line" variant="compact" />
      </FadeInUp>
      {directions ? (
        <Tabs>
          <TabList>
            {directions.map((item: any, index) => (
              <Tab key={index}>
                <TabIcon icon={item.icon} />
                {item.label}
              </Tab>
            ))}
          </TabList>

          {directions.map((item: any, index) => (
            <TabPanel
              key={index}
              dangerouslySetInnerHTML={{ __html: item.text }}
            />
          ))}
        </Tabs>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 6rem 10.972vw 6.25rem;
  text-align: center;

  .line {
    margin-left: auto;
    margin-right: auto;
  }

  .react-tabs {
    max-width: 50.625rem;
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
    margin: 3.75rem auto 0;
    .react-tabs__tab-list {
      border: 0;
    }
    .react-tabs__tab {
      display: inline-flex;
      align-items: center;
      border: 0;
      color: ${({ theme }) => theme.colors.variants.neutralDark4};
      font-size: 0.9375rem;
      font-weight: 300;
      line-height: 2rem;
      margin: 0 1.125rem;
      padding: 0;
      transition: 0.3s ease-out;

      svg {
        fill: ${({ theme }) => theme.colors.variants.neutralDark2};
        stroke: ${({ theme }) => theme.colors.variants.neutralDark2};
        height: 1.25rem;
        width: 1.5rem;
      }

      &:last-of-type {
        &:after {
          display: none;
        }
      }
      &:after {
        content: '';
        width: 0.125rem;
        height: 0.75rem;
        margin-left: 2.25rem;
        background: ${({ theme }) => theme.colors.variants.neutralDark4};
        opacity: 0.2;
      }
      &:focus {
        box-shadow: none;

        &:after {
          position: static;
        }
      }
      &.react-tabs__tab--selected {
        background: none;
        border-radius: 0;
        color: ${({ theme }) => theme.colors.variants.primaryLight2};

        svg {
          fill: ${({ theme }) => theme.colors.variants.primaryLight2};
          stroke: ${({ theme }) => theme.colors.variants.primaryLight2};
        }
      }

      @media (max-width: 767px) {
        margin: 0 1rem;

        &:after {
          margin-left: 2rem;
        }
      }

      @media (max-width: 424px) {
        margin: 0 0.5rem;

        &:after {
          margin-left: 1rem;
        }
      }
    }
    .react-tabs__tab-panel {
      color: ${({ theme }) => theme.colors.variants.neutralDark4};
      font-size: 1.125rem;
      font-weight: 300;
      line-height: 2rem;
      margin-top: 2.25rem;
      text-align: left;
      &.react-tabs__tab-panel--selected {
        animation: fadeInPanel 0.6s;
      }
    }
  }
  @keyframes fadeInPanel {
    from {
      opacity: 0;
      transform: translateY(2.25rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: 1199px) {
    .react-tabs {
      margin-top: 3.75rem;

      .react-tabs__tab-panel {
        font-size: 1.0625rem;
      }
    }
  }

  @media (max-width: 1023px) {
    padding-right: 1.875rem;
    padding-left: 1.875rem;

    .react-tabs {
      .react-tabs__tab-panel {
        font-size: 1rem;
      }
    }
  }
`
const Label = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-size: 1rem;
  line-height: 1.125rem;
  font-weight: 300;
  margin: 0 auto 1.875rem;
  max-width: 22.75rem;
  text-align: center;

  @media (max-width: 1199px) {
    margin-bottom: 1.25rem;
  }
`
const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-size: 3.125rem;
  line-height: 3.875rem;
  font-weight: 500;
  max-width: 34rem;
  margin: 0 auto;

  b {
    font-style: italic;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.variants.primaryLight2};
  }

  @media (max-width: 1199px) {
    max-width: none;
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
`
