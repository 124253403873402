import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  className?: string
  distance?: string
  latitude: string
  longitude: string
  title: string
}

export const Poi = memo(function Poi({ className, distance, title }: Props) {
  return (
    <Container className={className}>
      <Title>{title}</Title>
      {distance ? <Distance>{distance}</Distance> : null}
    </Container>
  )
})

const Container = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  position: relative;
  text-align: left;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Title = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 2rem;
`

const Distance = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1rem;
  margin-top: 6px;
  text-transform: uppercase;
`
